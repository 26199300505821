<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">상담 및 코칭 리포트 등록</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>작성일</th>
                            <td>
                                2020-01-13
                            </td>
                        </tr>
                        <tr>
                            <th>코칭일</th>
                            <td>
                                <input type="text" class="w-80px">
                            </td>
                        </tr>
                        <tr>
                            <th>구분</th>
                            <td>
                                <select class="w-200px">
                                    <option value="">2019</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>코멘트</th>
                            <td>
                                <textarea class="w-100per h-100px"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left">삭제</button>
                <button class="btn-default float-right">등록<!--저장--></button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'CCReportAddPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>